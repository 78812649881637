<template>
  <div>
    <h4>Consulta de miembros de {{ titulo }}</h4>
    <filtros-membresia
      v-model="busqueda"
      ref="busqueda"
      :tipo-membresia="tipo"
      @input="actualizar"
    />
    <div class="row acciones">
      <div class="col-6 text-left">
        Registros encontrados: {{ listadoMembresias.length }}
      </div>
      <div class="col-6 botones-accion">
        <b-button @click="exportarExcel" :disabled="listadoMembresias.length == 0">Exportar Excel</b-button>
        <b-button v-if="$parent.permisos(permisos.agregar[tipo])" :to="`/membresia/agregar/${tipo}/`">Agregar</b-button>
      </div>
    </div>
    <ve-table
      rowKeyFieldName="membresia_id"
      border-y
      :fixed-header="true"
      :columns="columnas"
      :table-data="listadoMembresias"
      :max-height="400"
      :cell-selection-option="{ enable: false }"
      :virtualScrollOption="{ enable: true }"
    />
    <b-modal ref="modalBaja" title="Capturar posible baja">
      <b-form-row>
        <b-col cols="12">
          <b-form-group label="Nombre">
            <b-input readonly v-model="membresia_baja.nombre_completo"></b-input>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Motivo de la baja">
            <b-form-select
              v-model="membresia_baja.motivo"
              :options="causas_baja"
              value-field="motivo_baja_id"
              text-field="motivo"
            >
              <b-form-select-option :value="null"
                >Seleccione el motivo de la baja</b-form-select-option
              >
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-form-row>
      <template #modal-footer="{ hide }">
        <b-button variant="primary" class="float-right" @click="guardarBaja()">
          Guardar 
        </b-button>
        <b-button class="float-right" @click="hide()"> Cerrar </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import api from "@/api";
import { API_URL } from "@/api";
import { Constantes } from "@/api";
import FiltrosMembresia from "@/components/FiltrosMembresia.vue";
import { nextTick } from 'vue';
import { exportarExcel } from "@/utils"
import { required } from "vuelidate/lib/validators";

export default {
  name: "Membresia",
  components: { FiltrosMembresia },
  data() {
    return {
      API_URL: API_URL,
      Constantes: Constantes,
      listadoMembresias: [],
      membresia_baja: {
        motivo: null
      },
      causas_baja: [],
      tipo: Number.parseInt(this.$route.params.tipo),
      titulo:
        Constantes.TIPOS_MEMBRESIA[Number.parseInt(this.$route.params.tipo)],
      permisos: {
        agregar: ['','membresia-matrimonios-agregar','membresia-jovenes-agregar', 'membresia-mares-agregar', 'membresia-ae-agregar', 'membresia-adolescentes-agregar'],
        editar: ['','membresia-matrimonios-editar','membresia-jovenes-editar', 'membresia-mares-editar', 'membresia-ae-editar', 'membresia-adolescentes-editar'],
        status: ['','membresia-matrimonios-status','membresia-jovenes-status', 'membresia-mares-status', 'membresia-ae-status', 'membresia-adolescentes-status'],
        credencial: ['','membresia-matrimonios-credencial','membresia-jovenes-credencial', 'membresia-mares-credencial', 'membresia-ae-credencial', 'membresia-adolescentes-credencial'],
        posible_baja: ['','membresia-matrimonios-posible_baja','membresia-jovenes-posible_baja', 'membresia-mares-posible_baja', 'membresia-ae-posible_baja', 'membresia-adolescentes-posible_baja'],
        baja: ['','membresia-matrimonios-baja','membresia-jovenes-baja', 'membresia-mares-baja', 'membresia-ae-baja', 'membresia-adolescentes-baja'],
      },
      busqueda: {},
      columnas: [
        {
          field: "membresia_id",
          key: "a",
          title: "Id",
          align: "left",
        },
        {
          field: "diocesis",
          key: "b",
          title: "Diócesis",
          align: "left",
        },
        {
          field: "sector",
          key: "c",
          title: "Sector",
          align: "left",
        },
        {
          field: "nombre_completo",
          key: "d",
          title: "Nombre",
          align: "left",
        },
        {
          field: "rol",
          key: "e",
          title: "Rol",
          align: "left",
        },
        {
          field: "estatus",
          key: "f",
          title: "Estatus",
          align: "left",
        },
     
        {
          field: "nivel",
          key: "g",
          title: "Nivel",
          align: "center",
        },
        {
          renderBodyCell: (d) => {
            return (
              <span>
                <router-link 
                  to={"/membresia/editar/" + d.row.membresia_id} 
                  v-show={this.$parent.permisos(this.permisos.editar[this.tipo])}
                  title="Editar"
                >
                  <font-awesome-icon size="lg" icon="edit"   />
                </router-link>
                &nbsp;
                <a
                  target="_blank"
                  href={API_URL + "/membresia/credencial/" + d.row.membresia_id }
                  v-show={this.$parent.permisos(this.permisos.credencial[this.tipo])}
                  title="Imprimir credencial"
                >
                  <font-awesome-icon size="lg" icon="address-card" v-show={d.row.alta} />
                </a>
                &nbsp;
                <a                
                  href="javascript:;"
                  onClick={()=>this.modalBaja(d.row)}    
                  v-show={this.$parent.permisos(this.permisos.posible_baja[this.tipo])}    
                  title="Marcar como posible baja"          
                >
                  <font-awesome-icon size="lg" icon="user-times" v-show={d.row.alta} />
                </a>
              </span>
            );
          },
          key: "h",
          title: "Acciones",
          align: "center",
        },
      ],
    };
  },
  validations() {
    return {
      membresia_baja: {
        motivo: {
          required,
        },      
      },
    };  
  },
  methods: {
    exportarExcel() {
      exportarExcel(this.listadoMembresias);
    },
    actualizar() {
      api.listarMembresiaTipo(this.tipo, this.busqueda).then((r) => {
        if (Array.isArray(r.data)) {
          this.listadoMembresias = r.data;
        } else {
          this.listadoMembresias = [];
        }
      });
    },
    agregar() {
      this.titulo = "Agregar sector";
      this.sector = { diocesis_id: null };
      this.$refs.detalle.show();
    },
    editar(elemento) {
      (this.titulo = "Editar sector"),
        (this.sector = Object.assign({}, elemento));
      this.$refs.detalle.show();
    },
    eliminar(elemento) {
      this.$parent
        .confirmar("Confirma eliminar el sector: " + elemento.nom_sector)
        .then(() => {
          api
            .eliminarSector(elemento.sector_id)
            .then((resp) => {
              let r = resp.data;
              if (r.respuesta) {
                this.actualizarSectores();
              } else {
                this.$parent.aviso(r.mensaje);
              }
            })
            .catch((error) => {
              this.$parent.aviso("No se pudo eliminar " + error);
            });
        });
    },
    guardar() {
      this.$v.sector.$touch();
      if (!this.$v.sector.$invalid) {
        api
          .guardarSector(this.sector)
          .then((resp) => {
            let r = resp.data;
            if (r.respuesta) {
              this.$refs.detalle.hide();
              this.actualizarSectores();
            } else {
              this.$parent.error(
                "Error al guardar la información " + r.mensaje
              );
            }
          })
          .catch((error) => {
            this.$parent.error("Error al guardar la información " + error);
          });
      } else {
        this.$parent.error("Información incompleta");
      }
    },
    guardarBaja() {
      this.$v.membresia_baja.$touch();
      if (!this.$v.membresia_baja.$invalid) {
        api
          .bajaMembresia(this.membresia_baja)
          .then((resp) => {
            let r = resp.data;
            if (r.respuesta) {
              this.$refs.modalBaja.hide();
              this.actualizar();
            } else {
              this.$parent.error(
                "Error al marcar la membresia como baja " + r.mensaje
              );
            }
          })
          .catch((error) => {
            this.$parent.error("Error al marcar la membresia como baja " + error);
          });
      } else {
        this.$parent.error("Por favor selecciona el motivo de la baja");
      }
    },
    modalBaja(membresia) {
      this.membresia_baja = membresia;
      this.membresia_baja.motivo = null;
      this.$refs.modalBaja.show();
    },
    estatus(validation) {
      if (validation.$dirty && !validation.$error) return "dirty";
      return "error";
    },
  },
  mounted() {
    api.getMotivosBaja().then((r)=>{
      this.causas_baja = r.data
    });

    this.$watch(
      () => this.$route.params,
      async () => {
        this.tipo = Number.parseInt(this.$route.params.tipo);
        this.titulo = Constantes.TIPOS_MEMBRESIA[this.tipo];
        this.busqueda = {};
        this.listadoMembresias = [];
        await nextTick();
        this.$refs.busqueda.inicializa(this.tipo);        
      }
    );
  },
};
</script>

